<template>
    <div class="contact">
        <dl>
            <dt>官方邮箱：</dt>
            <dd>{{ contact.email }}</dd>
        </dl>
        <dl>
            <dt>投诉举报：</dt>
            <dd>{{ contact.report }}</dd>
        </dl>
        <dl>
            <dt>商务合作：</dt>
            <dd>{{ contact.bd }}</dd>
        </dl>
    </div>
</template>

<script>
    export default {
        name: "Contact",
        computed: {
            contact() {
                return this.$store.state.contact
            }
        }
    }
</script>

<style scoped lang="less">
    .contact {
        padding:0 40px;
        dl {
            font-size:20px;
            display:flex;
            margin:24px 0;
            column-gap:24px;
            &:first-child {margin-top:48px;}
            dt {color:#868686;}
            dd {margin:0;color:#3F3F3F;}
        }
    }
</style>